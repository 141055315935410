import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectStoppageType } from 'reports/stoppagesPareto/selectors'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import { compose } from 'redux'
import { CHANGEOVER_ENGINEERING_ID, CHANGEOVER_SETUP_ID } from './StoppagesParetoReportGraph'

function truncate (string, length) {
  if (string.length > length) {
    return string.substr(0, length) + '...'
  } else {
    return string
  }
}

const messages = defineMessages({
  engineeringDuration: {
    id: 'oee.locationChangeoversDurations.engineeringDuration',
    defaultMessage: 'Engineering'
  },
  setupDuration: {
    id: 'oee.locationChangeoversDurations.setupDuration',
    defaultMessage: 'Setup'
  }
})

const StoppagesParetoReportGraphLabel = ({ x, y, stroke, payload, stoppageType, intl, ...rest }) => {
  if (!payload || !payload.value) {
    return null
  }

  let label
  if (stoppageType) {
    label = `${stoppageType.symbol} - ${truncate(stoppageType.description, 10)}`
  } else {
    const id = payload.value

    if (id === CHANGEOVER_SETUP_ID) {
      label = intl.formatMessage(messages.setupDuration)
    }

    if (id === CHANGEOVER_ENGINEERING_ID) {
      label = intl.formatMessage(messages.engineeringDuration)
    }
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {label}
      </text>
    </g>
  )
}

StoppagesParetoReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.shape({
    value: PropTypes.any.isRequired
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  intl: intlShape
}

const mapStateToProps = (state, { payload: { value: stoppageTypeId } }) => ({
  stoppageType: selectStoppageType(state, stoppageTypeId)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(StoppagesParetoReportGraphLabel)
