import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { allById, allIds, customById, receiveCollection } from 'lib/reducers'
import Actions from 'reports/stoppagesPareto/actions'
import navigation from 'containers/Navigation/reducer'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const plant = handleActions({}, { name: '' })

const locations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      allById: allById(locations),
      allIds: allIds(locations),
      isVisibleById: customById(locations, true)
    }),
    [Actions.SHOW_LOCATION]: (state, { payload: id }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: true }
    }),
    [Actions.HIDE_LOCATION]: (state, { payload: id }) => ({
      ...state,
      isVisibleById: { ...state.isVisibleById, [id]: false }
    }),
    [Actions.SHOW_ALL]: state => ({
      ...state,
      isVisibleById: state.allIds.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    }),
    [Actions.HIDE_ALL]: state => ({
      ...state,
      isVisibleById: state.allIds.reduce((acc, val) => {
        acc[val] = false
        return acc
      }, {})
    })
  },
  { allIds: [], visibleIds: [], allById: {}, reportById: {} }
)

const stoppageTypes = handleActions(
  {
    [Actions.RECEIVE_STOPPAGE_TYPES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const reports = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

export default combineReducers({
  config,
  init,
  plant,
  navigation,
  locations,
  stoppageTypes,
  reports
})
