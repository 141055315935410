import { connect } from 'react-redux'
import { selectReports } from 'reports/stoppagesPareto/selectors'
import ParetoReportGraph from 'components/ParetoReportGraph'

import StoppagesParetoReportGraphTooltip from './StoppagesParetoReportGraphTooltip'
import StoppagesParetoReportGraphLabel from './StoppagesParetoReportGraphLabel'

export const CHANGEOVER_SETUP_ID = 'changeover_setup'
export const CHANGEOVER_ENGINEERING_ID = 'changeover_engineering'

const dataFormatter = duration => `${Math.round(duration)} min.`

const mapStateToProps = (state, ownProps) => ({
  data: selectReports(state),
  dataFormatter,
  dataKey: 'duration',
  GraphLabel: StoppagesParetoReportGraphLabel,
  GraphTooltip: StoppagesParetoReportGraphTooltip,
  isPrinting: ownProps.isPrinting,
})

export default connect(mapStateToProps)(ParetoReportGraph)
