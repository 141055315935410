import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PrintContextConsumer } from 'react-to-print'
import Loader from 'components/Loader'
import ReportContainer from 'components/ReportContainer'
import StoppagesParetoReportHeader from 'reports/stoppagesPareto/StoppagesParetoReportHeader'
import StoppagesParetoReportLocations from 'reports/stoppagesPareto/StoppagesParetoReportLocations'
import StoppagesParetoReportGraph from 'reports/stoppagesPareto/StoppagesParetoReportGraph'

const StoppagesParetoReport = ({ isLoaded, plant }) => {
  if (isLoaded) {
    return (
      <ReportContainer selector='#stoppages-report.report'>
        {({ isPrinting }) => (
          <React.Fragment>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <StoppagesParetoReportHeader handlePrint={handlePrint} />
              )}
            </PrintContextConsumer>
            <StoppagesParetoReportLocations />
            <StoppagesParetoReportGraph isPrinting={isPrinting} />
          </React.Fragment>
        )}
      </ReportContainer>
    )
  } else {
    return <Loader full />
  }
}

StoppagesParetoReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished,
})

export default connect(mapStateToProps)(StoppagesParetoReport)
