import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectStoppageType } from 'reports/stoppagesPareto/selectors'
import { FormattedMessage } from 'react-intl'
import { CHANGEOVER_ENGINEERING_ID, CHANGEOVER_SETUP_ID } from './StoppagesParetoReportGraph'
import { compose } from 'redux'
import { defineMessages, injectIntl, intlShape } from 'react-intl'

const messages = defineMessages({
  engineeringDuration: {
    id: 'oee.locationChangeoversDurations.engineeringDuration',
    defaultMessage: 'Engineering'
  },
  setupDuration: {
    id: 'oee.locationChangeoversDurations.setupDuration',
    defaultMessage: 'Setup'
  }
})

const StoppagesParetoReportGraphTooltip = ({ stoppageType, payload, intl, ...rest }) => {
  if (!payload) {
    return null
  }

  const [duration, total] = payload

  if (!duration || !total) {
    return null
  }

  let symbol
  let description
  if (stoppageType) {
    symbol = stoppageType.symbol
    description = stoppageType.description
  } else {
    const { id } = payload[0].payload
    if (id === CHANGEOVER_SETUP_ID) {
      symbol = intl.formatMessage(messages.setupDuration)
    }

    if (id === CHANGEOVER_ENGINEERING_ID) {
      symbol = intl.formatMessage(messages.engineeringDuration)
    }
  }

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>{symbol}</h4>
        <div>
          {description}
        </div>
      </div>

      <div className='report-graph-tooltip__footer'>
        <table>
          <tbody>
            <tr>
              <td>
                <b>
                  <FormattedMessage id='reports.stoppagesPareto.duration' />:
                </b>
              </td>
              <td>{Math.round(duration.value)} min.</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage id='reports.stoppagesPareto.percent' />:
                </b>
              </td>
              <td>{Math.round(total.payload.percent * 1000) / 10.0} %</td>
            </tr>
            <tr>
              <td>
                <b>
                  <FormattedMessage id='reports.stoppagesPareto.cumulative' />:
                </b>
              </td>
              <td>{Math.round(total.value * 1000) / 10.0} %</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

StoppagesParetoReportGraphTooltip.propTypes = {
  label: PropTypes.any,
  payload: PropTypes.array,
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  intl: intlShape
}

const mapStateToProps = (state, { label: stoppageTypeId }) => ({
  stoppageType: selectStoppageType(state, stoppageTypeId)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(StoppagesParetoReportGraphTooltip)
